@import "variable";

/*Font Family Start*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.f-DM-sans {
  font-family: 'DM Sans', sans-serif;
}

@font-face {
  font-family: 'Topol';
  src: url('../fonts/topol/Topol-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.f-topol-bol {
  font-family: 'Topol';
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/*Font Family End*/

body, html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  color: $Black-Color;
  background-color: $Secondary-Color;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
}

::selection {
  color: $White-Color;
  background: $Primary-Color;
}

/* Track */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(97, 88, 116, .2);
  border-radius: 10px;

}

::-webkit-scrollbar-thumb {
  background: $Primary-Color;
  border-radius: 10px;
}


div.modal-body::-webkit-scrollbar-track {
  margin: 50px;
}
div.table-responsive::-webkit-scrollbar {
  width: 4px !important;
  height: 4px;
}


* {
  margin: 0;
  padding: 0;
}

a {
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

button {
  outline: none;
  border: none;
  background: transparent;
}

input::placeholder {
  color: $Light-Grey-Color;
  opacity: .5;
  font-size: 18px;
}

/*Custom checkbox and Radio Start*/
.cp {
  cursor: pointer;
}
.white-space-nowrap{
  white-space: nowrap;

}
.word-break{
  word-break: break-word;
}
.c-checkbox {
  display: inline-flex;
  cursor: pointer;
}

.c-checkbox > span {
  color: $Primary-Color;
  font-size: 14px;
}

input[type = 'checkbox'] {
  height: 15px;
  width: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-right: 10px;
  margin-top: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid $white-light;
  border-radius: 3px;
  outline: none;
  transition-duration: 0.3s;
  background-color: white;
  cursor: pointer;

  &:hover {
    border: 1px solid $Blue-Color;
  }
}

input[type = 'checkbox']:checked {
  border: 1px solid $Blue-Color;
  background-color: $Blue-Color;
  position: relative;
}

.color-white {
  color: white;
}

input[type = 'checkbox']:checked::after {
  content: '\2713';
  font-size: 15px;
  height: 15px;
  width: 15px;
  min-width: 15px;
  min-height: 15px;
  display: block;
  text-align: center;
  color: white;
  position: absolute;
  left: -0.04em;
  bottom: 0.09em;
}

input[type = 'checkbox']:active {
  border: 1px solid $Primary-Color;
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border: 1px solid $white-light;
  background-color: $White-Color;
  margin-right: 7px;
}

input[type='radio']:checked {
  border: 4px solid white;
  background-color: $Primary-Color;
  position: relative;
  z-index: 0;
}

input[type='radio']:checked::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 1px solid $Primary-Color;
  z-index: 1;
}

/*Custom checkbox and Radio End*/

/*Custom Modal Style Start*/
.modal-backdrop.show {
  opacity: .9;
  background-color: $Primary-Color;
}

/*Custom Modal Style End*/

/*Fonts Start*/
.f-12 {
  font-size: 12px
}

.f-14 {
  font-size: 14px;
  line-height: 20px;
}

.f-16 {
  font-size: 16px;
  @media screen and (max-width: 576px) {
    font-size: 14px
  }
}

.f-18 {
  font-size: 18px;
  @media screen and (max-width: 576px) {
    font-size: 16px
  }
}

.f-20 {
  font-size: 20px;
  @media screen and (max-width: 576px) {
    font-size: 18px
  }
}

.f-22 {
  font-size: 22px;
  @media screen and (max-width: 576px) {
    font-size: 18px
  }
}

.f-24 {
  font-size: 24px;
  line-height: 45px;
  @media screen and (max-width: 576px) {
    font-size: 20px;
    line-height: 30px;
  }
}
.f-25 {
  font-size: 25px;
  line-height: 30px;
  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.f-28 {
  font-size: 28px;
  line-height: 30px;
}

.f-32 {
  font-size: 32px;
  line-height: 40px
}

.f-35 {
  font-size: 35px;
  line-height: 40px;
  @media screen and (max-width: 991px) {
    font-size: 28px;
    line-height: 34px;
  }
}
.f-41 {
  font-size: 41px;
  line-height: 44px;
  @media screen and (max-width: 991px) {
    font-size: 22px;
    line-height: 25px;
  }
}
.f-59 {
  font-size: 59px;
  line-height: 62px;
  @media screen and (max-width: 991px) {
    font-size: 35px;
    line-height: 44px;
  }
}

.f-54 {
  font-size: 54px;
  line-height: 65px;
  @media screen and (max-width: 991px) {
    font-size: 30px;
    line-height: 35px;
  }
}

.f-w-thi {
  font-weight: 100;
}

.f-w-lig {
  font-weight: 300;
}

.f-w-bol {
  font-weight: bold;
}

.f-w-reg {
  font-weight: normal;
}

.f-w-exbol {
  font-weight: 900;
}

.f-w-blc {
  font-weight: 900;
}

/*Fonts End*/

/*Common Things Start*/
.hr-line {
  border: 1px solid #514D59;
  opacity: .1;
}

.scroll-top-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.logo {
  max-width: 250px;
  @media screen and (max-width: 767px) {
    max-width: 215px;
  }
}


.c-topol-title {
  font-family: Topol;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
}

.c-input-label {
  font-size: 18px;
  font-weight: bold;
}

.c-checkbox-label {
  font-size: 14px;
  color: $Primary-Color;
  margin-bottom: 0;
}

.c-input-style {
  font-size: 18px;
  border: 1px solid $white-light;
  border-radius: 8px;
  height: 60px;
  width: 100%;
  padding: 15px;
  @media screen and (max-width: 767px) {
    height: 50px;
    padding: 10px;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $Black-Color;
  }
}

.c-textarea-style {
  font-size: 18px;
  border: 1px solid $white-light;
  border-radius: 8px;
  width: 100%;
  padding: 15px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $Black-Color;
    opacity: .5;
  }
}

.c-select-style{
  position: relative;
  z-index: 0;
  .c-option-style{
    position: absolute;
    top: 60px;
    left: 30px;
    right: 0;
    z-index: 1;
  }
}

.example-button {
  display: inline-block;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  line-height: 19px;
  font-size: 18px;
  line-height: 19px;
  min-width: 150px;
  padding: 15px 32px;
  color: $White-Color;
  background-color: $Red-Color;

  &:focus:not([disabled]), &:hover:not([disabled]) {
    outline: none;
    background-color: $Dark-Red-Color;
    cursor: pointer;
    color: $White-Color;
    text-decoration: none;
  }
}

.c-btn {
  display: inline-block;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  line-height: 19px;
  height: 60px;
  font-size: 18px;
  line-height: 19px;
  min-width: 150px;
  padding: 15px 32px;
  color: $White-Color;
  background-color: $Red-Color;

  &-auto-height {
    height: auto;
  }

  &-link {
    &:hover {
      color: $White-Color;
      text-decoration: none;
    }
  }

  &:focus:not([disabled]), &:hover:not([disabled]) {
    outline: none;
    background-color: $Dark-Red-Color;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    height: 50px;
    padding: 15px 20px;
  }
}

.c-outline-primary-btn {
  height: 60px;
  border: 1px solid #615874;
  min-width: 150px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 19px;
  background-color: transparent;
  color: $Primary-Color;
  padding: 5px 32px;

  &:focus:not([disabled]), &:hover:not([disabled]) {
    outline: none;
    background-color: $Primary-Color;
    color: $White-Color;
  }
  @media screen and (max-width: 767px) {
    height: 50px;
    padding: 5px 15px;
  }

}
.c-success-btn{
  background-color: $Green-Color;
  &:focus:not([disabled]), &:hover:not([disabled]) {
    background-color: $Dark-Green-Color;
  }
}
.c-delete-btn {
  height: 60px;
  border: 1px solid #615874;
  border-radius: 8px;
  font-size: 18px;
  line-height: 19px;
  background-color: transparent;
  color: $Primary-Color;
  padding: 5px 40px;

  &:focus:not([disabled]), &:hover:not([disabled]) {
    outline: none;
    background-color: $Primary-Color;
    color: $White-Color;
  }

  @media screen and (max-width: 767px) {
    height: 50px;
    padding: 5px 15px;
  }

}

.c-blue-btn {
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  border: none;
  color: $White-Color;
  background-color: $Blue-Color;

  &:hover {
    background-color: $Dark-Blue-Color;
  }

  &-comment {
    background-color: white;
    color: $Dark-Blue-Color;

    svg {
      fill: $Dark-Blue-Color;
    }

    &:hover {
      background-color: $Dark-Blue-Color;
      color: $White-Color;
    }
    &-active {
      background-color: $Dark-Blue-Color;
      color: $White-Color;
      svg {
        fill: $White-Color;
      }
    }
  }
}

.c-back-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $Primary-Color;
  background-color: $Secondary-Color;
  height: 40px;
  min-width: 120px;
  border: 1px solid $Primary-Color;
  border-radius: 5px;
  padding: 10px 15px 7px 15px;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    background-color: $Primary-Color;
    color: $White-Color;

    .back-arrow {
      fill: $White-Color
    }
  }
}

.c-outline-btn {
  font-size: 16px;
  border: 1px solid $White-Color;
  border-radius: 5px;
  min-height: 50px;
  background-color: $Primary-Color;
  color: $White-Color;
  padding: 18px 20px;
  @media screen and (max-width: 767px) {
    padding: 8px;
    font-size: 14px;
  }
}

.c-tag-btn {
  min-height: 25px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  color: $Light-Primary-Color;
  padding: 6px 11px;
  border-radius: 100px;
  font-size: 13px;
  line-height: 13px;
  background-color: $Extra-Light-Primary-Color;
  outline: none;
  border: 1px solid $Extra-Light-Primary-Color;
  text-transform: uppercase;

  &-green {
    border: 1px solid $blue-green-color;
    background-color: $blue-green-color;
    color: $Dark-Blue-Color;
    border-radius: 5px;
  }
}

.c-tag-outline-btn {
  color: $Primary-Color;
  background-color: $White-Color;
  border: 1px solid $Primary-Color;
}

.c-tag-white-outline-btn {
  color: $White-Color;
  background-color: transparent;
  border: 1px solid $White-Color;
}
.c-tag-owner{
  color: $Blue-Color;
  background-color: transparent;
  border: 1px solid $Blue-Color;
}
.c-tag-invite-pending{
  min-width: 117px;
  color: $Dark-Orange-Color;
  background-color: transparent;
  border: 1px solid $Dark-Orange-Color;
}
.c-tag-inactive{
  color: $Light-Primary-Color;
  background-color: transparent;
  border: 1px solid $Light-Primary-Color;
}
.c-tag-width{
  min-width: 117px;
}
.c-blue-link {
  color: $Light-Blue-Color;
  text-decoration: none;

  &:hover {
    color: $Light-Blue-Color;
    text-decoration: underline;
  }
}

.c-dark-link {
  color: $Primary-Color;
  text-decoration: underline;

  &:hover {
    color: $Primary-Color;
    text-decoration: underline;
  }
}
.owner-tag {
  display: inline-block;
  font-size: 12px;
  line-height: 13px;
  min-height: 14px;
  border-radius: 100px;
  text-align: center;
  color: $Blue-Color;
  border: 1px solid $Blue-Color;
  padding: 5px 10px;
}

.c-red-link {
  color: $Red-Color;
  text-decoration: none;

  &:hover {
    color: $Red-Color;
    text-decoration: underline;
  }
}

.result-plus {
  background-color: $Green-Color;
  color: $White-Color;
}

.result-minus {
  background-color: $Light-Red-Color;
  color: $White-Color;
}

.example-footer-extra-spacing {
  @media screen and (min-width: 992px) {
    padding-bottom: 200px;
  }
}

.modal-header {
  border: none;
}
.delete-example-modal{
  max-width: 500px;
  margin-top: 150px;
}

.close {
  opacity: .9;
}

.text-white {
  color: $White-Color;
}

.text-blue {
  color: $Blue-Color;
}

.text-light-blue {
  color: $Light-Blue-Color;
}

.text-red {
  color: $Red-Color;
}

.text-primary-color {
  color: $Primary-Color;
}

.text-light-primary {
  color: $Light-Primary-Color;
}

.text-primary-grey {
  color: $Primary-Color;
}

.text-light-black {
  color: $Light-Black-Color;
}

.text-light-grey {
  color: $Light-Grey-Color;
}

.text-orange {
  color: $Orange-Color;
}

.text-yellow {
  color: $Yellow-Color;
}

.text-grey {
  color: $Dark-grey;
}

.opacity-5 {
  opacity: .5;
}

.bg-red {
  background-color: $Red-Color;
}

.bg-grey-blue {
  background-color: $Primary-Color;
}

.bg-dark-secondary {
  background-color: $Dark-Secondary-Color;
}

/*Common Things End*/

/*Svg Style Start*/
svg:not(.emptyFill) {
  fill: $Default-Svg-Color;

  &:hover {
    fill: $Primary-Color;
  }
}

.close-btn {
  fill: $Primary-Color;
}
.liked-example-close-btn{
  fill: $Light-Black-Color;
  width: 14px;
  height: 14px;
}
.down-arrow-header {
  fill: #fff;

  &:hover {
    fill: #fff;
  }
}

.back-arrow {
  fill: $Primary-Color;

  &:hover {
    fill: $White-Color
  }
}

.edit-icon {
  &:hover {
    opacity: .8;
  }
}

.example-buttons {
  &:hover {
    .fav-icon, .share-icon, .comment-icon {
      stroke: #67BEC9;
      fill: #67BEC9;
    }

    .fav-filled-icon {
        fill: #67BEC9;
      }
    }
  .fav-icon {
    stroke: $Default-Svg-Color;
    fill: transparent;
    transition: 0.3s ease all;
  }

  .fav-filled-icon {
    stroke: black;
    fill: #67BEC9;
    transition: 0.3s ease all;
  }
}

.share-popup-icon {
  fill: $Green-Color;
  width: 24px;
  height: 26px;
}

.scroll-top-icon {
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
}

.big-upload-icon {
  width: 250px;
  height: 175px;
  opacity: .25;
  @media screen and (max-width: 768px) {
    width: 200px;
    height: 150px;
  }
}
.big-edit-icon {
  width: 250px;
  height: 250px;
  opacity: .25;
  @media screen and (max-width: 991px) {
    width: 180px;
    height: 180px;
  }
}
.user-plus-icon{
  width: 244px;
  height: 170px;
  @media screen and (max-width: 991px) {
    width: 200px;
    height: 150px;
  }
}
.user-detail-icon{
  width: 244px;
  height: 170px;
  @media screen and (max-width: 991px) {
    width: 200px;
    height: 150px;
  }
}
.big-inbox-icon {
  width: 190px;
  height: 171px;
  opacity: .25;
  @media screen and (max-width: 768px) {
    width: 150px;
    height: 130px;
  }
}


.csv-icon{
  width: 100%;
  height: 200px;
  @media screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
}


/*Svg Style End*/

/*Badge Style Start*/
.reviewer {
  color: $Blue-Color;
  font-family: '';
}

.senior-reviewer {
  color: $Light-Primary-Color;
}

.contributor {
  color: $Orange-Color;
}

.senior-contributor {
  color: $Dark-grey;
}

.top-contributor {
  color: $Yellow-Color;
}

/*Badge Style End*/

/*Common Banner Start*/
.example-banner {
  position: relative;
  z-index: 0;

  .clip-banner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 55% 100%, 0 90%);
    z-index: -1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $Primary-Color;
      z-index: -3;
    }
  }
}

/*Common Banner End*/

/*Example Record Start*/
.example-record {
  .example-record-text {
    font-weight: 700;
    font-size: 12px;
  }

  .example-record-img {
    max-width: 150px;
    max-height: 30px;
    width: 100%;
  }
}


/*Example Record End*/

/*Common File Input Start*/
.c-file-upload-wrapper {
  background: $White-Color;
  border: 1px dashed $Light-Primary-Color;
  color: #514D59;
  height: 300px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    height: 200px;
  }

  .img-upload-error {
    position: absolute;
    top: 20px;
    margin: 0 auto;
  }

  .c-file-upload-input {
    cursor: pointer;
    height: 300px;
    width: 100%;
    opacity: 0;
    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  .c-file-upload-overlay {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 auto;
    pointer-events: none;
    z-index: -1
  }
  .z-index2{
    z-index: 2!important;
  }

  .addEdit-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    width: 100%;
    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }
}

.file-upload-icon {
  width: 56px;
  height: 46px;
}

/*Common File Input End*/

/*Table Style Start*/
.c-table{
  margin-bottom: 20px;
}
.c-th{
  font-size: 16px;
  font-weight: 700;
  color: $Primary-Color;
  text-transform: uppercase;
}
.table td{
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
}
.table tbody tr{
  &:hover{
    background: $Dark-Secondary-Color;
  }
}
.c-table-border{
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  border-top: 1px solid rgba(112, 112, 112, 0.25);
  margin: 10px 0;
}
/*Table Style End*/


/*Toast style*/
.Toastify__close-button{
  padding-left: 20px;
  opacity: 1 !important;
}
/*Toast style*/

/*text editor style start*/
.ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
  z-index: 4 !important;
}
/*text editor style end*/
